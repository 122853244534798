.home {
    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5em;

        ul {
            list-style-type: none;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 0;

            li {
                margin: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            li>.active {
                background-color: white;
                padding: 10px;
                border-radius: 25px;
            }
        }

        .cta-btn {
            display: flex;
            justify-content: center;
            align-items: center;

            .buy-btn {
                width: 10em;
                padding: 10px;
                height: auto;
                border-radius: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #363636;
                color: white;
                font-size: 18px;
                font-weight: 500;
            }
        }
    }

    .home-main {

        width: 100%;
        height: 75vh;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        display: flex;
        justify-content: space-between;

        .hm-right {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 40%;

            .img-main {
                width: 40em;
                // visibility: hidden;
                // transform: scaleX(-1);
            }

            .hmr-socials {
                border: 2px solid #ed8599;
                padding: 10px;
                height: 23em;
                border-radius: 40px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;

                .social-btn {
                    padding: 10px;
                    border-radius: 50%;
                }

                .twitter {
                    background-color: #363636;
                }

                .telegram {
                    background-color: #0082BC;
                }

                .solana {
                    background-color: #05a3c9;
                }

            }
        }

        .hm-left {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60%;
            flex-direction: column;
            text-align: start;
            font-family: 'Crog-Black';

            .header1 {
                line-height: 1em;
                margin: 0;
                font-size: 14em;
                color: #ed8599;
                -webkit-text-stroke: 8px #222121;
                padding: 0;

            }

            .header2 {
                line-height: 1em;
                font-size: 14em;
                padding: 0;
                margin: 0;
                color: #74902d;
                -webkit-text-stroke: 8px #222121;
            }

            .code {
                display: flex;
                width: 60%;
                border: 1px solid #74902d;
                border-radius: 10px;
                padding: 10px;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .how-to-buy {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 0em;

        .htb-wrap {
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .line {
                width: -webkit-fill-available;
                height: 2px;
                background-color: #74902d;
            }

            .htb-header {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .htb-card {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .img-heart {
                    width: 30em;
                    height: 30em;
                }

                .htbc-body {
                    width: 50%;

                    .htcb-v {
                        width: 30em;
                    }
                }
            }

            .htb-h1 {
                margin-bottom: 1em;
                font-size: 50px;
                font-family: 'Crog-Black';
            }

        }

    }

    .tokenomics {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40em;
        background: rgba(255, 255, 255, 0.1);

        .token-wrap {
            width: 70%;
            border: 1px solid #05a3c9;
            border-radius: 15px;
            padding: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            display: flex;
            justify-content: space-around;
            align-items: center;

            .chart-img {
                width: 30em;
            }

            .chart-text {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                height: 100%;

                .cht-h1 {
                    color: #363636;
                    -webkit-text-stroke: 2px #74902d;
                    font-size: 4em;
                }

                .cht-h2 {
                    font-family: 'Nohemi';
                    font-size: 40px;
                }
            }
        }
    }

    .contact {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .contact-wrap {
            width: 70%;
            display: flex;
            justify-content: center;
            align-items: center;

            flex-direction: column;

            .email {
                color: #74902d;
                // -webkit-text-stroke: 2px #ed8599;
                font-size: 3em;
            }
        }

        .line {
            width: -webkit-fill-available;
            height: 2px;
            background-color: #74902d;
        }

        .cw-h1 {
            margin-bottom: 1em;
            font-size: 50px;
            font-family: 'Crog-Black';
        }

    }
}

@media screen and (max-width:768px) {
    .home {
        .header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-top: 0.5em;

            ul {
                display: none;
                list-style-type: none;
                border-radius: 25px;
                justify-content: center;
                align-items: center;
                margin: 0;
                padding: 0;

                li {
                    margin: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                li>.active {
                    background-color: white;
                    padding: 10px;
                    border-radius: 25px;
                }
            }

            .ulli {
                display: none;
            }

            .cta-btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .buy-btn {
                    width: 10em;
                    padding: 10px;
                    height: auto;
                    border-radius: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #363636;
                    color: white;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
        }

        .home-main {
            margin-top: 3em;
            width: 100%;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            display: flex;
            height: auto;
            flex-direction: column;
            justify-content: space-between;

            .hm-right {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;

                .img-main {
                    margin-top: 2.5em;
                    width: 14em;
                    transform: scaleX(-1);
                }

                .hmr-socials {
                    margin-right: 0;
                    margin-top: -4em;
                    transform: rotate(90deg);
                    transform-origin: center;
                    border: 2px solid #ed8599;
                    padding: 10px;
                    border-radius: 40px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;

                    .social-btn {
                        padding: 10px;
                        border-radius: 50%;
                        transform: rotate(90deg);
                        transform-origin: center;
                    }

                    .twitter {
                        background-color: #363636;
                        transform: rotate(90deg);
                        transform-origin: center;
                    }

                    .telegram {
                        background-color: #0082BC;
                        transform: rotate(90deg);
                        transform-origin: center;
                    }

                    .solana {
                        background-color: #05a3c9;
                        transform: rotate(90deg);
                        /* Rotate the div 90 degrees */
                        transform-origin: center;
                    }

                }
            }

            .hm-left {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                flex-direction: column;
                text-align: start;
                font-family: 'Crog-Black';
                line-height: 4em;

                .header1 {
                    margin: 0;
                    font-size: 5em;
                    color: #ed8599;
                    -webkit-text-stroke: 4px #222121;
                    padding: 0;

                }

                .header2 {
                    font-size: 5em;
                    padding: 0;
                    margin: 0;
                    color: #74902d;
                    -webkit-text-stroke: 4px #222121;
                }

                .code {
                    display: flex;
                    width: 90%;
                    border: 1px solid #74902d;
                    border-radius: 10px;
                    padding: 10px;
                    justify-content: center;
                    align-items: center;

                    .code-p {
                        font-size: 12px;
                    }
                }
            }
        }

        .how-to-buy {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 0em;
            height: auto;

            .htb-h1 {
                margin-bottom: 1em;
                font-size: 60px;
                font-family: 'Nohemi';
            }

            .htb-wrap {
                width: 95%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-direction: column;
                .htb-header{
                    display: none;
                }
                .htb-card {
                    width: 100%;
                    border-radius: 15px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 10px; // Adding padding here
                    box-sizing: border-box; // Ensures padding is included in width and height
                    margin-top: 1em;
                    .img-heart {
                     display: none;
                    }
                    .htbc-body {
                        width: 100%;

                        .htcb-v {
                            width:-webkit-fill-available;
                            height: 60vh;
                            object-fit:fill ;
                        }
                    }

                    .htb-img {
                        height: 50%; // Adjust height to fill the card
                        width: 100%;
                        object-fit: cover;
                        border-radius: 15px;
                        box-sizing: border-box; // Ensures padding is included in width and height
                    }

                    .htb-middle {
                        height: 30%;
                        width: 100%;
                        padding: 10px;
                        background-color: #f3f3f3;
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                    }

                    .htb-footer {
                        height: 20%;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .htbf-h4 {
                            font-size: 40px;
                            color: white;
                            font-family: 'Nohemi';
                        }

                        .launch-web {
                            height: 70px;
                            width: 70px;
                            border-radius: 50%;
                            border: 2px solid white;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
        .tokenomics {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40em;
            background: rgba(255, 255, 255, 0.1);
            .token-wrap {
                width: 90%;
                border: 1px solid #05a3c9;
                border-radius: 15px;
                padding: 10px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(10px);
                -webkit-backdrop-filter: blur(10px);
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-direction: column;
    
                .chart-img {
                    width: 10em;
                }
    
                .chart-text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    height: 100%;
    
                    .cht-h1 {
                        color: #363636;
                        -webkit-text-stroke: 2px #74902d;
                        font-size: 4em;
                        margin-top: 1em;
                    }
    
                    .cht-h2 {
                        font-family: 'Nohemi';
                        font-size: 30px;
                    }
                }
            }
        }
        .contact {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
    
            .contact-wrap {
                width: 70%;
                display: flex;
                justify-content: center;
                align-items: center;
    
                flex-direction: column;
    
                .email {
                    color: #74902d;
                    // -webkit-text-stroke: 2px #ed8599;
                    font-size: 2em;
                }
            }
    
            .line {
                width: -webkit-fill-available;
                height: 2px;
                background-color: #74902d;
            }
    
            .cw-h1 {
                margin-bottom: 1em;
                font-size: 30px;
                font-family: 'Crog-Black';
            }
    
        }
    }
}