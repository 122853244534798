@font-face {
    font-family: "Crog-Black";
    src: url("./assets/fonts/Crog-Black.otf");
}

@font-face {
    font-family: "Crog-Bold";
    src: url("./assets/fonts/Crog-Bold.otf");
}

@font-face {
    font-family: 'Crog-Medium';
    src: url("./assets/fonts/Crog-Medium.otf");
}
@font-face {
    font-family: "Nohemi";
    src: url("./assets/fonts/Nohemi-Bold.ttf");
}


body{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='7.5' width='7.5'%3E%3Ccircle cx='10' cy='10' r='5' fill='%23e0e0e0' /%3E%3C/svg%3E ");

}